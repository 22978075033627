<template>
  <div>
    <div v-if="sourceData && sourceData.recordId">
      <div>
        <div class="row pt-3 pb-3 align-items-center border-bottom">
          <div class="col-xl-9 font-weight-bolder">
            评论列表
          </div>
          <div class="col-xl-3 text-right">
            <button
              class="btn btn-sm btn-primary"
              data-toggle="modal"
              data-target="#comment"
            >
              评论
            </button>
          </div>
        </div>
        <template v-for="item in sourceData.commentList">
          <div :key="item.recordId" v-if="!item.auditRecordId">
            <div
                class="row align-items-center pt-2 pb-2 border-bottom"
                v-if="!item.commentId && !item.commentFaId"
            >
              <div class="col-xl-1">
                <span class="symbol symbol-35 symbol-light-success" v-if="item.employee">
                  <img v-if="item.employee.downloadUrl" alt="Pic" :src="item.employee.downloadUrl" />
                  <span v-else-if="item.employee.empChar" class="symbol-label font-size-h5 font-weight-bold">
                    {{item.employee.empChar}}
                  </span>
                </span>
              </div>
              <div class="col-xl-9" style="word-break: break-word">
                <div>{{ item.name }}&nbsp;评论&nbsp;{{ item.content }}</div>
                <div class="row align-items-center">
                  <div class="col-xl-9">{{ item.createDateStr }}</div>
                </div>
              </div>
              <div class="col-xl-2 text-right">
                <button
                    class="btn btn-sm btn-outline-primary px-1 py-1"
                    v-on:click="replyComment(item)"
                >
                  回复</button
                >&nbsp;
                <button
                    class="btn btn-sm btn-outline-primary px-1 py-1"
                    v-on:click="close(item)"
                >
                  <span v-if="!item.commentFlag">收起</span>
                  <span v-else>展开</span>
                </button>
              </div>
            </div>
            <template v-for="comme in sourceData.commentList">
              <div :key="comme.recordId" v-if="!item.commentFlag">
                <div
                    class="row align-items-center p-2 alert-dark bg-bottom text-secondary border-bottom border-light"
                    v-if="comme.commentFaId == item.recordId"
                >
                  <div class="col-xl-1">
                    <span class="symbol symbol-35 symbol-light-success" v-if="comme.employee">
                      <img v-if="comme.employee.downloadUrl" alt="Pic" :src="comme.employee.downloadUrl" />
                      <span v-else-if="comme.employee.empChar" class="symbol-label font-size-h5 font-weight-bold">
                        {{comme.employee.empChar}}
                      </span>
                    </span>
                  </div>
                  <div class="col-xl-8">
                    <div style="word-break: break-word">
                      {{ comme.name }}&nbsp;回复&nbsp;
                      <span v-if="comme.replyName">{{ comme.replyName }}</span>
                      <span v-else>{{ item.name }}</span>
                      &nbsp;{{ comme.content }}
                    </div>
                    <div class="row align-items-center">
                      <div class="col-xl-9">{{ comme.createDateStr }}</div>
                    </div>
                  </div>
                  <div class="col-xl-3 text-right">
                    <button
                        class="btn btn-sm btn-outline-secondary px-1 py-1"
                        v-on:click="replyCom(item, comme)"
                    >
                      回复
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div
      class="modal fade"
      id="comment"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                评论内容
                <textarea
                  class="form-control"
                  v-model="comments.content"
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              v-on:click="commit"
            >
              发布
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  props: {
    status: String,
    sourceData: Object,
    user: Object
  },
  data() {
    return {
      comments: {}
    };
  },
  mounted() {
    this.comments = {};
  },
  methods: {
    replyComment: function(item) {
      if (item && item.recordId) {
        this.comments.commentFaId = item.recordId;
      }
      $("#comment").modal();
    },
    replyCom: function(item, commen) {
      if (item && item.recordId) {
        this.comments.commentFaId = item.recordId;
      }
      if (commen && commen.recordId) {
        this.comments.commentId = commen.recordId;
      }
      $("#comment").modal();
    },
    close: function(item) {
      if (item.commentFlag) {
        this.$set(item, "commentFlag", false);
      } else {
        this.$set(item, "commentFlag", true);
      }
    },
    commit: function() {
      if (!this.comments || !this.comments.content) {
        alert("请填写评论内容");
        return;
      }
      if (!this.sourceData || !this.sourceData.recordId) {
        alert("当前页面失效，请退出重进");
        return;
      }
      if (!(this.user && this.user.employeeId)) {
        alert("用户未登陆，请登录");
        return;
      }
      if (!this.status){
        alert("评论回复链接错误");
        return;
      }
      if (this.status == "kybAudit"){
        this.comments.auditId = this.sourceData.recordId;
      }else if (this.status == "kybTask"){
        this.comments.taskId = this.sourceData.recordId;
      }else if (this.status == "kybDayThing"){
        this.comments.dayThingId = this.sourceData.recordId;
      }else if (this.status == "kybReport"){
        this.comments.reportId = this.sourceData.recordId;
      }else {
        alert("评论回复找不到链接错误");
        return;
      }
      this.comments.createdBy = {};
      this.comments.createdBy.recordId = this.user.employeeId;
      this.$axios
        .fetchPost("kybAudit/addAuditComment", this.comments)
        .then(data => {
          if (data && data.data) {
            this.sourceData.commentList = data.data;
            this.comments = {};
          }
          $("#comment").modal("hide");
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
